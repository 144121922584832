import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ToolsPage = ({ location }) => {
  const searchQuery = new URLSearchParams(location.search)
  const {
    strapi: { tables, materials, toolTypes, tools },
  } = useStaticQuery(graphql`
    query ToolsPageQuery {
      strapi {
        tables: kongsbergTables(sort: "name") {
          slug
          name
          id
        }
        materials: kongsbergMaterials(sort: "name") {
          id
          name
        }
        toolTypes: kongsbergToolTypes(sort: "name") {
          name
          id
        }
        tools: kongsbergTools(sort: "name") {
          id
          slug
          name
          description
          image {
            alternativeText
            id
            staticFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 528)
              }
            }
          }
          materials {
            id
            name
          }
          tables {
            id
            name
          }
          toolType {
            id
            name
          }
        }
      }
    }
  `)

  const [filter, setFilter] = React.useState({
    tables: searchQuery.get("table") ? [searchQuery.get("table")] : [],
  })

  const filtered = React.useMemo(() => {
    return tools.filter(tool => {
      const inTables =
        !filter.tables ||
        filter.tables.length === 0 ||
        filter.tables.find(tId => tool.tables.find(tt => tt.id === tId))
      const inMaterials =
        !filter.materials ||
        filter.materials.length === 0 ||
        filter.materials.find(mId => tool.materials.find(mm => mm.id === mId))
      const inType =
        !filter.toolTypes ||
        filter.toolTypes.length === 0 ||
        filter.toolTypes.find(typeId => typeId === tool.toolType.id)
      const inQuery =
        !filter.query ||
        filter.query.toLowerCase().search(tool.name.toLowerCase()) > -1 ||
        tool.name.toLowerCase().search(filter.query.toLowerCase()) > -1
      return inTables && inMaterials && inType && inQuery
    })
  }, [filter])

  const updateFilter = (key, element) => {
    setFilter(f => {
      const newList = f[key] || []
      const index = newList.indexOf(element)
      if (index > -1) {
        newList.splice(index, 1)
      } else {
        newList.push(element)
      }
      return {
        ...f,
        [key]: newList,
      }
    })
  }

  return (
    <Layout>
      <SEO title="Værktøj til dit Kongsberg skærebord" />

      <div className="bg-brand-100 relative">
        <div className="absolute z-0 top-0 square-float flex justify-center w-full h-full overflow-hidden">
          <div className="absolute square bg-brand-200" />
          <div className="absolute square bg-brand-50" />
        </div>

        <div className="container relative mx-auto z-10">
          <div className="relative z-10 pt-32 pb-16 text-center">
            <h1 className="max-w-3xl mx-auto heading-lg mb-2">
              Find værktøj til dit Kongsberg skærebord
            </h1>
            <p className="max-w-2xl mx-auto text-2xl mb-0">
              Søg efter værktøj til dit Kongsberg skærebord herunder
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto grid grid-cols-6 mt-4 pb-32">
        <div className="col-span-6 md:col-span-2 lg:col-span-1 pb-8">
          <div className="hidden md:block">
            <div className="my-6">
              <input
                className="input max-w-full"
                type="text"
                placeholder="Søg"
                value={filter.query || ""}
                onChange={event =>
                  setFilter(f => ({ ...f, query: event.target.value }))
                }
              />
            </div>

            <div className="my-6">
              <div className="heading-sm">Skærebord</div>
              {tables.map(table => (
                <label key={table.id} className="block my-2">
                  <input
                    type="checkbox"
                    value={table.id}
                    checked={
                      filter.tables && filter.tables.indexOf(table.id) > -1
                    }
                    onChange={() => updateFilter("tables", table.id)}
                  />
                  <span className="ml-2">{table.name}</span>
                </label>
              ))}
            </div>

            <div className="my-6">
              <div className="heading-sm">Type</div>
              {toolTypes.map(type => (
                <label key={type.id} className="block my-2">
                  <input
                    type="checkbox"
                    value={type.id}
                    checked={
                      filter.toolTypes && filter.toolTypes.indexOf(type.id) > -1
                    }
                    onChange={() => {
                      updateFilter("toolTypes", type.id)
                    }}
                  />
                  <span className="ml-2">{type.name}</span>
                </label>
              ))}
            </div>

            <div className="my-6">
              <div className="heading-sm">Materiale</div>
              {materials.map(material => (
                <label key={material.id} className="block my-2">
                  <input
                    type="checkbox"
                    value={material.id}
                    checked={
                      filter.materials &&
                      filter.materials.indexOf(material.id) > -1
                    }
                    onChange={() => {
                      updateFilter("materials", material.id)
                    }}
                  />
                  <span className="ml-2">{material.name}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="col-span-6 md:col-span-4 lg:col-span-5">
          {filtered.length === 0 && (
            <div className="p-4 pt-32 text-center">
              <div className="heading-sm mb-1">
                Ingen resultater blev fundet
              </div>
              <div className="">Prøv at ændre dine filtre</div>
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {filtered.map(tool => (
              <Link
                to={`/tools/${tool.slug}/`}
                className="block group p-4"
                key={tool.id}
              >
                <div className="w-full pt-full bg-brand-100 rounded mb-2 relative">
                  <div className="absolute top-0 left-0 bottom-0 right-0 p-6">
                    {tool.image && tool.image.staticFile ? (
                      <GatsbyImage
                        image={getImage(tool.image.staticFile)}
                        alt={tool.image.alternativeText}
                        objectFit="contain"
                        className="w-full h-full transform transition-transform group-hover:scale-105"
                      />
                    ) : (
                      <div className="w-full h-full transform transition-transform group-hover:scale-105" />
                    )}
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-0">{tool.name}</h3>
                  <div className="w-0 transition-all -mt-1 h-1 group-hover:w-8 bg-brand-500" />
                  <p className="text-sm font-semibold text-brand-500">
                    {tool.toolType.name}
                  </p>
                  <p className="text-xs text-gray-500 mt-1 font-semibold">
                    {tool.materials.map(m => m.name).join(", ")}
                  </p>
                  <p className="mt-0 line-clamp-2">{tool.description}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ToolsPage
